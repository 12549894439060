export const prices = [40, 60, 80, 100, 120, 160];

export const calculateSubTotalAndServiceChargeAndTotalPriceWithServiceCharge = (
    quantities
) => {
    let SubTotal = 0;
    let serviceCharge = 0;

    for (let i = 0; i < quantities.length; i++) {
        const dishPrice = quantities[i] * prices[i];
        SubTotal += dishPrice;
    }

    serviceCharge = SubTotal * 0.1;
    const totalPriceWithServiceCharge = SubTotal + serviceCharge;

    return {
        SubTotal,
        serviceCharge,
        totalPriceWithServiceCharge,
    };
};

export const calculateTotalAmountFromEveryone = (eachPersonBillSummary) => {
    let total = 0;
    for (let i = 0; i < eachPersonBillSummary.length; i++) {
        total += eachPersonBillSummary[i].onePersonBillSummary;
    }
    return total;
}