import React from 'react';
import { prices } from './sushiro'
import './DishCalculator.css'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';


const DishCalculator = ({ quantities, addDish, minusDish }) => {
    return (
        <div class="eachDishCenter">
            {prices.map((price, index) => {
                return (
                    <div class={`dish dish${price}`}>
                        <Button variant="contained" size="large" onClick={() => minusDish(index)}>-{price}</Button>
                        {/* <span class="dish-label">Quantity: <span id="dish1Quantity">{quantities[index]}</span></span> */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #e0e0e0',
                                borderRadius: 2,
                                overflow: 'hidden',
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    padding: '8px 16px',
                                    paddingTop: '14px',
                                    paddingBottom: '14px'
                                }}>
                                <Typography variant="body2">{price}฿</Typography>
                            </Box>
                            <Box
                                sx={{
                                    padding: '8px 16px',
                                    paddingTop: '14px',
                                    paddingBottom: '14px',
                                    width: '100%',
                                    height: '20px',
                                    backgroundColor: 'white'
                                }}>
                                <Typography variant="body2">{quantities[index]}</Typography>
                            </Box>
                        </Box>

                        <Button variant="contained" size="large" onClick={() => addDish(index)}>+{price}</Button>
                    </div>
                );
            })}
        </div>
    )
}

export default DishCalculator;